
export default function Stats() {
  return (
<iframe
    src="https://pdx.us.private.sandbox.authe.io/private/analytics/metabase/public/dashboard/a029c053-086b-4645-8bd6-d942bb7d3efa?parameter_name=value&variable=x&version=LATEST"
    frameBorder={0}
    width="100%"
    height={900}
    allowTransparency
/>
  );
}
