import { HttpError } from "@refinedev/core";
import axios, {AxiosRequestConfig} from "axios";

export const axiosInstance = axios.create();

axiosInstance.interceptors.request.use(
    (config) => {
        const token = JSON.parse(localStorage.getItem('token') || '{}');
        console.log(config)
        config.headers = {
            Authorization: `Bearer ${token.__raw}`,
            Accept: "application/json, text/plain, */*",
            "Source-Platform": "dashboard",
            "Source-Region": "us-west-2",
            "Destination-Region": "us-west-2",
        };
        console.log(config)

        // The get requests should go via the Cloudfront distribution in order to make use of the origin groups and maybe caching
        if (config.url?.includes("/public/")){
            // Change api url based on the users token metadata like continent
            const continent = token[process.env.REACT_APP_BASE_URL + "/continent"] 
            const new_api_endpoint = "https://pdx." + continent + '.public.' + process.env.REACT_APP_API_BASE_URL
            console.log("Changing the API Endpoint to: " + new_api_endpoint)
            config.url = config.url?.replace('https://api.sandbox.authe.io', new_api_endpoint)
            console.log("Changed the API Endpoint to: " + config.url)
        }
        else if (config.url?.includes("/private/")){
                // Change api url based on the users token metadata like continent
                const continent = token[process.env.REACT_APP_BASE_URL + "/continent"] 
                const new_api_endpoint = "https://pdx." + continent + '.private.' + process.env.REACT_APP_API_BASE_URL
                console.log("Changing the API Endpoint to: " + new_api_endpoint)
                config.url = config.url?.replace('https://api.sandbox.authe.io', new_api_endpoint)

                const new_path = "GET/" + continent + "/v0/"
                config.url = config.url?.replace('GET/v0/', new_path)
                console.log("Changed the API Endpoint to: " + config.url)     
        }
        else if (config.url?.includes("/management/")){
            // Change api url based on the users token metadata like continent
            const continent = token[process.env.REACT_APP_BASE_URL + "/continent"] 
            const new_api_endpoint = "https://pdx." + continent + '.management.' + process.env.REACT_APP_API_BASE_URL
            console.log("Changing the API Endpoint to: " + new_api_endpoint)
            config.url = config.url?.replace('https://api.sandbox.authe.io', new_api_endpoint)
            console.log("Changed the API Endpoint to: " + config.url)     
        }
        else{
                // Change api url based on the users token metadata like continent
                const continent = token[process.env.REACT_APP_BASE_URL + "/continent"] 
                const new_api_endpoint = "https://pdx." + continent + '.private.' + process.env.REACT_APP_API_BASE_URL
                console.log("Changing the API Endpoint to: " + new_api_endpoint)
                config.url = config.url?.replace('https://api.sandbox.authe.io', new_api_endpoint)
                console.log("Changed the API Endpoint to: " + config.url)               
        }
        console.log(config)
        
        return config;
    },
    (error) => {
        return Promise.reject(error);
    }
);
  
axiosInstance.interceptors.response.use(
    (response) => {
        // Fix for migration to new api structure
        if (response.data.result){
            response.data = response.data.result
        }

        // Return the error
        if (response.data.ok == false){
            console.log(response.data)
            const customError: HttpError = {
                message: response.data.error.reason,
                statusCode: response.data.statusCode,
            };
    
            return Promise.reject(customError);
        }
        
        return response;
    },
    (error) => {
        const customError: HttpError = {
            ...error,
            message: error.response?.data?.message,
            statusCode: error.response?.status,
        };

        return Promise.reject(customError);
    },
);

