import React, { ComponentProps } from 'react';
import { useNavigate } from 'react-router-dom';

import { IResourceComponentsProps } from "@refinedev/core";

import {
    List,
    useTable,
    DateField,
    TagField,
    ShowButton,
} from "@refinedev/antd";
import dayjs from "dayjs";
import { Table, Space } from "antd";

import { ITransaction } from "interfaces";
import { useAccount } from 'wagmi'


export const TransactionsList: React.FC<IResourceComponentsProps> = () => {
    const { address } = useAccount()
    const navigate = useNavigate();

    const { tableProps, sorter } = useTable<ITransaction>({
        meta:{"address": address, "chain": "sepolia"}
        // initialFilter: [
        //     {
        //         field: "address",
        //         operator: "eq",
        //         value: "0x71C7656EC7ab88b098defB751B7401B5f6d8976F"
        //     },
        // ],
    });

    return (
        <List>
            <Table {...tableProps} rowKey="hash">
                <Table.Column<ITransaction>
                    title="Hash" 
                    dataIndex="hash" 
                    render={(_, record: any) => {
                        return (
                            <TagField
                                value={`${
                                    record?.hash.substring(0, 10)
                                }` }
                            />
                        );
                    }}
                />
                {/* <Table.Column dataIndex="id" title="ID" /> */}
                <Table.Column
                    dataIndex="tracer_id"
                    title="Tracer_id"
                    key="tracer_id"
                 />
                <Table.Column dataIndex="address" title="Address" />
                <Table.Column
                    dataIndex="to_address"
                    title="To"
                    key="to_address"
                    render={(value: any) => {
                        // let value: ComponentProps<typeof TagField>["value"];
                        switch (value) {
                            case process.env.REACT_APP_PROXY_CONTRACT_ADDRESS?.toLowerCase():
                                return "AuthE Proxy"
                            case process.env.REACT_APP_CUSTOM_ERC20_CONTRACT_ADDRESS?.toLowerCase():
                                return "Custom ERC20 contract";
                            default:
                                return value
                        }
                    }}
                 />
                <Table.Column
                    dataIndex="from_address"
                    title="From Address"
                    key="from_address"
                    render={(value: any) => {
                        // let value: ComponentProps<typeof TagField>["value"];
                        switch (value) {
                            case process.env.REACT_APP_PROXY_CONTRACT_ADDRESS?.toLowerCase():
                                value = "AuthE Proxy"
                                break;
                            case process.env.REACT_APP_CUSTOM_ERC20_CONTRACT_ADDRESS?.toLowerCase():
                                value = "Custom ERC20 contract";
                                break;
                            default:
                                break;
                        }
                        return <TagField  value={value} />;
                    }}
                 />
                <Table.Column
                    dataIndex="decoded_input_function"
                    title="Action"
                    key="decoded_input_function"
                    render={(value: any) => {
                        switch (value) {
                            case "UNKNOWN":
                                value = "..."
                                break;
                            default:
                                break;
                        }
                        return <TagField  value={value} />;
                    }}
                 />
                <Table.Column
                    dataIndex="status"
                    title="Status"
                    key="status"
                    render={(value : any) => {
                        let color: ComponentProps<typeof TagField>["color"];
                        switch (value) {
                            case "success":
                                color = "success";
                                break;
                            case "approved":
                                color = "success";
                                break;
                            case "approval_executed":
                                color = "success";
                                break;
                            case "denied":
                                color = "error";
                                break;
                            case "error":
                                color = "error";
                                break;   
                            case "approval_requested":
                                break;
                            case "request_tokens_from_owner":
                                value = "success request AuthE"
                                color = "success";
                                break;   
                            case "enable_auth_proxy":
                                value = "success AuthE Enabled"
                                color = "success";
                                break;           
                            case "not_authe_provider":
                                color = "warning";
                                break;   
                            case "transfer_with_auth_provider_disabled":
                                color = "warning";
                                value = "AuthE Provider not yet enabled";
                                break;   
                            case "failed":
                                color = "error";
                                value = "failed - no approval requested"
                                break;                                  
                            default:
                                break;
                        }
                        return <TagField value={value} color={color} />;
                    }}
                 />
                <Table.Column
                    dataIndex="error_reason"
                    title="Error reason"
                    key="error_reason"
                    render={(value : any) => {
                        if (value && value.includes("Error no permission documents found for from address")) {
                            value = "Add a permission document and set it in status 'Publish'"
                        }
                        else if (value) {
                            value = "Internal server error"
                        }
                        else{
                            value = ""
                        }

                        return <TagField value={value} />;
                    }}
                /> 
                 <Table.Column
                    dataIndex="created_at"
                    title="Created At"
                    key="created_at"
                    render={(value: any) => <DateField value={dayjs.unix(value)} format="LLL"></DateField>}
                />      
                <Table.Column<ITransaction>
                    title="Show more"
                    dataIndex="show"
                    render={(_, record: any) => (
                        <Space>
                            <ShowButton
                                hideText
                                size="small"
                                recordItemId={record.hash}
                            />
                        </Space>
                    )}
                />      
            </Table>
        </List>
    );
};
