import type { RefineThemedLayoutV2HeaderProps } from "@refinedev/antd";
import {
  Layout as AntdLayout,
  Space,
  Tag,
  theme,
  Avatar,
} from "antd";
import { ConnectButton } from '@rainbow-me/rainbowkit';
import { useAuth0 } from "@auth0/auth0-react";
import { Link } from "react-router-dom";

const { useToken } = theme;

export const Header: React.FC<RefineThemedLayoutV2HeaderProps> = ({
  sticky,
}) => {
  // const { data: user } = useGetIdentity<IUser>();
  const {
    user,
    isAuthenticated,
    loginWithRedirect,
    logout,
  } = useAuth0();
  
  const { token } = useToken();
  
  const headerStyles: React.CSSProperties = {
    backgroundColor: token.colorBgElevated,
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "center",
    padding: "0px 24px",
    height: "64px",
  };

  if (sticky) {
    headerStyles.position = "sticky";
    headerStyles.top = 0;
    headerStyles.zIndex = 1;
  }

  return (
    <AntdLayout.Header style={headerStyles}>
      <Space>
        <ConnectButton />
      </Space>
      <Link to="/settings">
      <Space
                style={{
                    float: "left",
                    marginRight: "10px",
                    paddingLeft: "10px"
                }}
            >
              <strong>{user?.name}</strong>
            </Space>   
              <Space
                    style={{
                        float: "left",
                        marginRight: "10px",
                        paddingLeft: "0px"
                    }}
                >
                <Avatar src={<img src={user?.picture} alt="avatar" />} />
              </Space>
            </Link>      
            <Space>
            <Tag color="geekblue">{user?.[process.env.REACT_APP_BASE_URL + '/role_permissions']?.length > 1 ? "Custom" : user?.[process.env.REACT_APP_BASE_URL + '/role_permissions']?.[0].name || "Error: No Role found"}</Tag>
            </Space>   
            <Tag color="success">{user?.[process.env.REACT_APP_BASE_URL + '/tenant']?.['is_root_tenant'] ? "" : user?.[process.env.REACT_APP_BASE_URL + '/tenant']?.name  || ""}</Tag>
    </AntdLayout.Header>
  );
};
