import React, { useEffect, useState } from 'react';

import { Show } from "@refinedev/antd";
const { Title, Text } = Typography;
import { Typography } from "antd";
import { usePrepareContractWrite, useContractWrite, erc20ABI, sepolia } from 'wagmi'

import { useDebounce } from 'use-debounce'
import { ethers } from "ethers";

import { encodeFunctionData } from 'viem'

export default function Example5() {
  const autheProxyAddress = process.env.REACT_APP_PROXY_CONTRACT_ADDRESS
  const customERC20ContractAddress = process.env.REACT_APP_CUSTOM_ERC20_CONTRACT_ADDRESS
  const ABI = [
    {
      "inputs": [
        {
          "internalType": "address",
          "name": "_oracle",
          "type": "address"
        },
        {
          "internalType": "bytes32",
          "name": "_jobId",
          "type": "bytes32"
        },
        {
          "internalType": "uint256",
          "name": "_fee",
          "type": "uint256"
        },
        {
          "internalType": "address",
          "name": "_link",
          "type": "address"
        }
      ],
      "stateMutability": "nonpayable",
      "type": "constructor"
    },
    {
      "anonymous": false,
      "inputs": [
        {
          "indexed": true,
          "internalType": "bytes32",
          "name": "id",
          "type": "bytes32"
        }
      ],
      "name": "ChainlinkCancelled",
      "type": "event"
    },
    {
      "anonymous": false,
      "inputs": [
        {
          "indexed": true,
          "internalType": "bytes32",
          "name": "id",
          "type": "bytes32"
        }
      ],
      "name": "ChainlinkFulfilled",
      "type": "event"
    },
    {
      "anonymous": false,
      "inputs": [
        {
          "indexed": true,
          "internalType": "bytes32",
          "name": "id",
          "type": "bytes32"
        }
      ],
      "name": "ChainlinkRequested",
      "type": "event"
    },
    {
      "anonymous": false,
      "inputs": [
        {
          "indexed": true,
          "internalType": "address",
          "name": "from",
          "type": "address"
        },
        {
          "indexed": true,
          "internalType": "address",
          "name": "to",
          "type": "address"
        }
      ],
      "name": "OwnershipTransferRequested",
      "type": "event"
    },
    {
      "anonymous": false,
      "inputs": [
        {
          "indexed": true,
          "internalType": "address",
          "name": "from",
          "type": "address"
        },
        {
          "indexed": true,
          "internalType": "address",
          "name": "to",
          "type": "address"
        }
      ],
      "name": "OwnershipTransferred",
      "type": "event"
    },
    {
      "inputs": [],
      "name": "acceptOwnership",
      "outputs": [],
      "stateMutability": "nonpayable",
      "type": "function"
    },
    {
      "inputs": [],
      "name": "api_url",
      "outputs": [
        {
          "internalType": "string",
          "name": "",
          "type": "string"
        }
      ],
      "stateMutability": "view",
      "type": "function"
    },
    {
      "inputs": [
        {
          "internalType": "bytes",
          "name": "_data",
          "type": "bytes"
        }
      ],
      "name": "convertCallDataToString",
      "outputs": [
        {
          "internalType": "string",
          "name": "",
          "type": "string"
        }
      ],
      "stateMutability": "pure",
      "type": "function"
    },
    {
      "inputs": [
        {
          "internalType": "bytes32",
          "name": "_data",
          "type": "bytes32"
        }
      ],
      "name": "convertHashToString",
      "outputs": [
        {
          "internalType": "string",
          "name": "",
          "type": "string"
        }
      ],
      "stateMutability": "pure",
      "type": "function"
    },
    {
      "inputs": [
        {
          "internalType": "bytes32",
          "name": "requestId",
          "type": "bytes32"
        },
        {
          "internalType": "bytes",
          "name": "bytesData",
          "type": "bytes"
        }
      ],
      "name": "fulfillBytes",
      "outputs": [],
      "stateMutability": "nonpayable",
      "type": "function"
    },
    {
      "inputs": [
        {
          "internalType": "string",
          "name": "key",
          "type": "string"
        }
      ],
      "name": "getApprovedTransaction",
      "outputs": [
        {
          "internalType": "string",
          "name": "",
          "type": "string"
        }
      ],
      "stateMutability": "view",
      "type": "function"
    },
    {
      "inputs": [],
      "name": "hash_result",
      "outputs": [
        {
          "internalType": "bytes32",
          "name": "",
          "type": "bytes32"
        }
      ],
      "stateMutability": "view",
      "type": "function"
    },
    {
      "inputs": [
        {
          "internalType": "bytes",
          "name": "buffer",
          "type": "bytes"
        }
      ],
      "name": "iToHex",
      "outputs": [
        {
          "internalType": "string",
          "name": "",
          "type": "string"
        }
      ],
      "stateMutability": "pure",
      "type": "function"
    },
    {
      "inputs": [],
      "name": "oracle_result",
      "outputs": [
        {
          "internalType": "bool",
          "name": "",
          "type": "bool"
        }
      ],
      "stateMutability": "view",
      "type": "function"
    },
    {
      "inputs": [],
      "name": "owner",
      "outputs": [
        {
          "internalType": "address",
          "name": "",
          "type": "address"
        }
      ],
      "stateMutability": "view",
      "type": "function"
    },
    {
      "inputs": [
        {
          "internalType": "address",
          "name": "_resource_address",
          "type": "address"
        },
        {
          "internalType": "string",
          "name": "_functionName",
          "type": "string"
        },
        {
          "internalType": "bytes",
          "name": "_data",
          "type": "bytes"
        }
      ],
      "name": "requestApproval",
      "outputs": [],
      "stateMutability": "nonpayable",
      "type": "function"
    },
    {
      "inputs": [
        {
          "internalType": "string",
          "name": "_value",
          "type": "string"
        }
      ],
      "name": "setApiUrl",
      "outputs": [],
      "stateMutability": "nonpayable",
      "type": "function"
    },
    {
      "inputs": [
        {
          "internalType": "address",
          "name": "to",
          "type": "address"
        }
      ],
      "name": "transferOwnership",
      "outputs": [],
      "stateMutability": "nonpayable",
      "type": "function"
    },
    {
      "inputs": [],
      "name": "withdrawLink",
      "outputs": [],
      "stateMutability": "nonpayable",
      "type": "function"
    }
  ]


  const custom_ERC20_ABI =  [
    {
      "inputs": [
        {
          "internalType": "uint256",
          "name": "total",
          "type": "uint256"
        }
      ],
      "stateMutability": "nonpayable",
      "type": "constructor"
    },
    {
      "anonymous": false,
      "inputs": [
        {
          "indexed": true,
          "internalType": "address",
          "name": "tokenOwner",
          "type": "address"
        },
        {
          "indexed": true,
          "internalType": "address",
          "name": "spender",
          "type": "address"
        },
        {
          "indexed": false,
          "internalType": "uint256",
          "name": "tokens",
          "type": "uint256"
        }
      ],
      "name": "Approval",
      "type": "event"
    },
    {
      "anonymous": false,
      "inputs": [
        {
          "indexed": true,
          "internalType": "address",
          "name": "from",
          "type": "address"
        },
        {
          "indexed": true,
          "internalType": "address",
          "name": "to",
          "type": "address"
        },
        {
          "indexed": false,
          "internalType": "uint256",
          "name": "tokens",
          "type": "uint256"
        }
      ],
      "name": "Transfer",
      "type": "event"
    },
    {
      "inputs": [
        {
          "internalType": "address",
          "name": "owner",
          "type": "address"
        },
        {
          "internalType": "address",
          "name": "delegate",
          "type": "address"
        }
      ],
      "name": "allowance",
      "outputs": [
        {
          "internalType": "uint256",
          "name": "",
          "type": "uint256"
        }
      ],
      "stateMutability": "view",
      "type": "function"
    },
    {
      "inputs": [
        {
          "internalType": "address",
          "name": "delegate",
          "type": "address"
        },
        {
          "internalType": "uint256",
          "name": "numTokens",
          "type": "uint256"
        }
      ],
      "name": "approve",
      "outputs": [
        {
          "internalType": "bool",
          "name": "",
          "type": "bool"
        }
      ],
      "stateMutability": "nonpayable",
      "type": "function"
    },
    {
      "inputs": [
        {
          "internalType": "address",
          "name": "tokenOwner",
          "type": "address"
        }
      ],
      "name": "balanceOf",
      "outputs": [
        {
          "internalType": "uint256",
          "name": "",
          "type": "uint256"
        }
      ],
      "stateMutability": "view",
      "type": "function"
    },
    {
      "inputs": [
        {
          "internalType": "bytes",
          "name": "_data",
          "type": "bytes"
        }
      ],
      "name": "convertCallDataToString",
      "outputs": [
        {
          "internalType": "string",
          "name": "",
          "type": "string"
        }
      ],
      "stateMutability": "pure",
      "type": "function"
    },
    {
      "inputs": [
        {
          "internalType": "bytes32",
          "name": "_data",
          "type": "bytes32"
        }
      ],
      "name": "convertHashToString",
      "outputs": [
        {
          "internalType": "string",
          "name": "",
          "type": "string"
        }
      ],
      "stateMutability": "pure",
      "type": "function"
    },
    {
      "inputs": [],
      "name": "decimals",
      "outputs": [
        {
          "internalType": "uint8",
          "name": "",
          "type": "uint8"
        }
      ],
      "stateMutability": "view",
      "type": "function"
    },
    {
      "inputs": [
        {
          "internalType": "address",
          "name": "_address",
          "type": "address"
        }
      ],
      "name": "getUseAuthProxy",
      "outputs": [
        {
          "internalType": "bool",
          "name": "",
          "type": "bool"
        }
      ],
      "stateMutability": "view",
      "type": "function"
    },
    {
      "inputs": [
        {
          "internalType": "bytes",
          "name": "buffer",
          "type": "bytes"
        }
      ],
      "name": "iToHex",
      "outputs": [
        {
          "internalType": "string",
          "name": "",
          "type": "string"
        }
      ],
      "stateMutability": "pure",
      "type": "function"
    },
    {
      "inputs": [],
      "name": "name",
      "outputs": [
        {
          "internalType": "string",
          "name": "",
          "type": "string"
        }
      ],
      "stateMutability": "view",
      "type": "function"
    },
    {
      "inputs": [
        {
          "internalType": "address",
          "name": "receiver",
          "type": "address"
        }
      ],
      "name": "requestTokensFromOwner",
      "outputs": [
        {
          "internalType": "bool",
          "name": "",
          "type": "bool"
        }
      ],
      "stateMutability": "nonpayable",
      "type": "function"
    },
    {
      "inputs": [
        {
          "internalType": "address",
          "name": "_address",
          "type": "address"
        },
        {
          "internalType": "string",
          "name": "_value",
          "type": "string"
        }
      ],
      "name": "setApprovedTransaction",
      "outputs": [
        {
          "internalType": "bool",
          "name": "",
          "type": "bool"
        }
      ],
      "stateMutability": "nonpayable",
      "type": "function"
    },
    {
      "inputs": [
        {
          "internalType": "address",
          "name": "_address",
          "type": "address"
        }
      ],
      "name": "setUseAuthProxy",
      "outputs": [
        {
          "internalType": "bool",
          "name": "",
          "type": "bool"
        }
      ],
      "stateMutability": "nonpayable",
      "type": "function"
    },
    {
      "inputs": [],
      "name": "symbol",
      "outputs": [
        {
          "internalType": "string",
          "name": "",
          "type": "string"
        }
      ],
      "stateMutability": "view",
      "type": "function"
    },
    {
      "inputs": [],
      "name": "totalSupply",
      "outputs": [
        {
          "internalType": "uint256",
          "name": "",
          "type": "uint256"
        }
      ],
      "stateMutability": "view",
      "type": "function"
    },
    {
      "inputs": [
        {
          "internalType": "address",
          "name": "receiver",
          "type": "address"
        },
        {
          "internalType": "uint256",
          "name": "numTokens",
          "type": "uint256"
        }
      ],
      "name": "transfer",
      "outputs": [
        {
          "internalType": "bool",
          "name": "",
          "type": "bool"
        }
      ],
      "stateMutability": "nonpayable",
      "type": "function"
    },
    {
      "inputs": [
        {
          "internalType": "address",
          "name": "owner",
          "type": "address"
        },
        {
          "internalType": "address",
          "name": "buyer",
          "type": "address"
        },
        {
          "internalType": "uint256",
          "name": "numTokens",
          "type": "uint256"
        }
      ],
      "name": "transferFrom",
      "outputs": [
        {
          "internalType": "bool",
          "name": "",
          "type": "bool"
        }
      ],
      "stateMutability": "nonpayable",
      "type": "function"
    }
  ]

  const [toApproval, setToApproval] = React.useState('')
  const [debouncedToApproval] = useDebounce(toApproval, 500)

  const [amountApproval, setAmountApproval] = React.useState('')
  const [debouncedAmountApproval] = useDebounce(amountApproval ? ethers.parseEther(amountApproval) : '1', 500)
  
  const [encodedData, setEncodedData] = React.useState('')
  const [debouncedToEncodedData] = useDebounce(encodedData, 500)

  const [buttonClicked, setButtonClicked] = React.useState(false)

    const { config: config_request_approval } = usePrepareContractWrite({
        address: autheProxyAddress,
        abi: ABI,
        functionName: 'requestApproval',
        gasLimit: 50000000,
        args: [ customERC20ContractAddress, "transfer", encodedData],
        onSettled(data, error) {
            console.log('Settled', { data, error })
        },
        cacheTime: 0,
        watch: true
    });
    const { data: data_request_approval, isLoading: is_loading_request_approval, isSuccess: is_success_request_approval, write: requestApproval } = useContractWrite(config_request_approval)
 
    useEffect(() => {
        console.log("effect")

        if(toApproval && amountApproval && encodedData && buttonClicked){
            setButtonClicked(false)
            console.log("send request")
            console.log(encodedData)
            requestApproval?.() 
        } 
        else{
            // console.log("do nothing")
            // console.log("toApproval", toApproval)
            // console.log("amountApproval", amountApproval)
            // console.log("encodedData", encodedData)
            // console.log("buttonClicked", buttonClicked)
        }
      }, [toApproval, amountApproval, encodedData]);

   async function OnClickRequestApproval(){
        // console.log("OnClickRequestApproval clickd")
        // console.log("toApproval", toApproval)
        // console.log("amountApproval", amountApproval)
        // console.log("encodedData", encodedData)
        // console.log("buttonClicked", buttonClicked)
        if(buttonClicked){
            setButtonClicked(false)
            requestApproval?.()
        } else{
            setButtonClicked(true)
        }

        // setEncodedData(encodeFunctionData({
        //     abi: erc20ABI,
        //     functionName: 'transfer',
        //     args: [toApproval? toApproval : autheProxyAddress,  debouncedAmountApproval? debouncedAmountApproval : '1']
        // }))

        // if(buttonClicked){
        //     setButtonClicked(false)
        //     requestApproval?.()
        // } else{
        //     setButtonClicked(true)
        // }

        const data  = encodeFunctionData({
            abi: erc20ABI,
            functionName: 'transfer',
            args: [toApproval? toApproval : autheProxyAddress,  debouncedAmountApproval? debouncedAmountApproval : '1']
        })

        if(data == encodedData && encodedData != "" && encodedData){
            setButtonClicked(false)
            requestApproval?.()
        }
        else{
            setEncodedData(data)
        }
    }

    const { data: data_read_approval, isSuccess: is_success_read_approval, isError: is_error_read_approval, isLoading: is_loading_read_approval } = useContractRead({
        address: customERC20ContractAddress,
        abi: custom_ERC20_ABI,
        functionName: 'getUseAuthProxy',
        args: [address],
        onSuccess(data) {
          console.log('Success contract_read_data', data, contract_read_data, address)
        },
        onError(error) {
          console.log('Error contract_read_data', error, contract_read_data, address)
        },
        watch: true,
      })

  return (
    <Show >
     

    <br></br>
    <br></br>
    <Title level={5}>Step 4 - Request approval </Title>


      <form
      onClick={(e) => {
        
        e.preventDefault()
        // OnClickRequestApproval()

        // requestApproval?.() 
      }}
    >
        0x560EcF1541389d71484374CFeb750847525582be
        <br></br>
      <input
        aria-label="Recipient"
        onChange={(e) => {
            setToApproval(e.target.value)

        }}
        placeholder="0xA0Cf…251e"
        value={toApproval}
      />
      <input
        aria-label="Amount (ether)"
        onChange={(e) => {
            setAmountApproval(e.target.value)
            // setEncodedData(encodeFunctionData({
            //     abi: erc20ABI,
            //     functionName: 'transfer',
            //     args: [ toApproval? toApproval : autheProxyAddress,  e.target.value]
            //   }))
        }}
        placeholder="0.05"
        value={amountApproval}
      />
       {/* <input
        aria-label="Input data"
        disabled={true}
        onChange={(e) => {
            setEncodedData(e.target.value)
        }}
        value={encodedData}
      />      */}
      <button onClick={OnClickRequestApproval} disabled={!requestApproval || !amountApproval ||  !toApproval} >
            Request approval via the AuthE proxy contact
        </button>
      <div>
      </div>
    </form>
    is transaction approved:
    {isSuccessBalanceOf && <div>Address <strong>{address}</strong> and token value has not been approved yet: <strong>{dataBalanceOf?.formatted} {dataBalanceOf?.symbol}</strong> </div>} 

  </Show>
    
    
  );
}