import { newModel, MemoryAdapter } from "casbin.js";

export const model = newModel(`
[request_definition]
r = sub, obj, act

[policy_definition]
p = sub, obj, act

[role_definition]
g = _, _

[policy_effect]
e = some(where (p.eft == allow))

[matchers]
m = g(r.sub, p.sub) && keyMatch(r.obj, p.obj) && regexMatch(r.act, p.act)
`);

// Values are coming from Auth0 and permissions-data repo to generate below
export const adapter = new MemoryAdapter(`
p, Admin, activity-feed, (list)
p, Admin, authflow, (list)
p, Admin, decode, (invoke)
p, Admin, disputes, (create)
p, Admin, disputes, (edit)
p, Admin, disputes, (list)
p, Admin, disputes, (show)
p, Admin, encode, (invoke)
p, Admin, evaluate-permission-request, (invoke)
p, Admin, events, (list)
p, Admin, events, (show)
p, Admin, examples, (list)
p, Admin, permission-document, (validate)
p, Admin, permission-documents, (create)
p, Admin, permission-documents, (delete)
p, Admin, permission-documents, (edit)
p, Admin, permission-documents, (list)
p, Admin, permission-documents, (show)
p, Admin, permission-documents, (sign)
p, Admin, settings, (list)
p, Admin, signature, (validate)
p, Admin, smart-contract, (read)
p, Admin, stats, (list)
p, Admin, stats, (show)
p, Admin, tranactions, (create)
p, Admin, transaction, (validate)
p, Admin, transactions, (list)
p, Admin, transactions, (process)
p, Admin, transactions, (show)


p, Writer, activity-feed, (list)
p, Writer, authflow, (list)
p, Writer, disputes, (create)
p, Writer, disputes, (edit)
p, Writer, disputes, (list)
p, Writer, disputes, (show)
p, Writer, events, (list)
p, Writer, events, (show)
p, Writer, examples, (list)
p, Writer, permission-documents, (create)
p, Writer, permission-documents, (edit)
p, Writer, permission-documents, (list)
p, Writer, permission-documents, (show)
p, Writer, settings, (list)
p, Writer, stats, (list)
p, Writer, stats, (show)
p, Writer, tranactions, (create)
p, Writer, transactions, (list)
p, Writer, transactions, (show)

p, Reader, activity-feed, (list)
p, Reader, authflow, (list)
p, Reader, disputes, (list)
p, Reader, disputes, (show)
p, Reader, events, (list)
p, Reader, events, (show)
p, Reader, examples, (list)
p, Reader, permission-documents, (list)
p, Reader, permission-documents, (show)
p, Reader, settings, (list)
p, Reader, stats, (list)
p, Reader, stats, (show)
p, Reader, transactions, (list)
p, Reader, transactions, (show)
`);