import { AxiosInstance } from "axios";
import { RequestQueryBuilder, CondOperator } from "@nestjsx/crud-request";
import { DataProvider } from "@refinedev/core";
import {
    handleFilter,
    handlePagination,
    handleSort,
    handleJoin,
    axiosInstance,
} from "./utils";


const public_get_api_base_path = "public/GET/v0"
const public_any_api_base_path = "public/ANY/v0"
const private_get_api_base_path = "private/GET/v0"
const private_any_api_base_path = "private/ANY/v0"

export const dataProvider = (
    apiUrl: string,
    httpClient: AxiosInstance = axiosInstance,
): Required<DataProvider> => ({
    getList: async ({ resource, pagination, filters, sorters, meta }) => {
        console.log("from list")
        const url = `${apiUrl}/${resource}`;

        // console.log(url)
        let query = RequestQueryBuilder.create();

        query = handleFilter(query, filters);
        query = handleJoin(query, meta?.join);
        query = handlePagination(query, pagination);
        query = handleSort(query, sorters);

        // const { data } = await httpClient.get(`${url}?${query.query()}`);
        
        console.log(resource)

        if (resource == "transactions" ){
            console.log("Getting transactions")
            console.log(meta)
            const address = meta?.address 
            const chain = meta?.chain
            const { data } = await httpClient.get(`${apiUrl}/${private_get_api_base_path}/${resource}?address=${address}&chain=${chain}`);
            console.log("Finished Getting transactions")
            console.log(data)
            return {
                data: data,
                total: 100,
            };
        }
        else if (resource == "disputes" ){
            console.log("Getting disputes")
            console.log(meta)
            const address = meta?.address 
            const chain = meta?.chain
            const { data } = await httpClient.get(`${apiUrl}/${private_get_api_base_path}/${resource}?address=${address}&chain=${chain}`);
            console.log("Finished Getting disputes")
            console.log(data)
            return {
                data: data,
                total: 100,
            };
        }
        else if (resource == "permission-documents" ){
            console.log("Getting permisison documents")
            console.log(meta)
            const address = meta?.address 
            const chain = meta?.chain
            const { data } = await httpClient.get(`${apiUrl}/${public_get_api_base_path}/${resource}?address=${address}&chain=${chain}`);
            console.log("Finished Getting permisison documents")
            console.log(data)
            return {
                data: data,
                total: 100,
            };
        }
        else{
            console.log(`${apiUrl}/${private_get_api_base_path}/${resource}?${query.query()}`)
            const { data } = await httpClient.get(`${apiUrl}/${private_get_api_base_path}/${resource}?address=${meta?.address}&version=ALL`);
            console.log(`${apiUrl}/${private_get_api_base_path}/${resource}?address=${meta?.address}&version=ALL`)
            console.log("end from list")
            console.log(data)
            return {
                data: data,
                total: 10,
            };
        }
    },

    getMany: async ({ resource, ids, meta }) => {
        console.log("from get many ")
        const url = `${apiUrl}/${resource}`;

        let query = RequestQueryBuilder.create().setFilter({
            field: "id",
            operator: CondOperator.IN,
            value: ids,
        });

        query = handleJoin(query, meta?.join);

        const { data } = await httpClient.get(`${url}?${query.query()}`);

        console.log("end from get many ")
        return {
            data,
        };
    },

    create: async ({ resource, variables }) => {
        console.log("create provider")
        console.log(variables)
        console.log(resource)

        if (resource == "permission-documents" ){
            // const url = `${apiUrl}/${resource}/${id}`;
            const url = `${apiUrl}/${private_any_api_base_path}/${resource}`;
            console.log(`${apiUrl}/${private_any_api_base_path}/${resource}`)
            const { data } = await httpClient.post(url, variables);
            
            return {
                data,
            };
        }
        else if (resource == "roles" ){
            const url = `${apiUrl}/management/permissions/ANY/US/v0/${resource}`;
            console.log(url)
            const { data } = await httpClient.post(url, variables);
            return {
                data: data,
            };
        }
        else if (resource == "users/invite" ){
            const url = `${apiUrl}/management/permissions/ANY/US/v0/${resource}`;
            console.log(url)
            const { data } = await httpClient.post(url, variables);
            return {
                data: data,
            };
        }
        else if (resource == "disputes" ){
            const url = `${apiUrl}/${private_any_api_base_path}/${resource}`;
            console.log(`${apiUrl}/${private_any_api_base_path}/${resource}`)
            const { data } = await httpClient.post(url, variables);
            return {
                data: data,
            };
        }
        else if (resource == "tenants" ){
            // const url = `${apiUrl}/${private_any_api_base_path}/${resource}`;
            const url = "https://pdx.us.management.sandbox.authe.io/ANY/US/v0/organizations/org_25758ccdf9ac443da123f197c39f4cd8/tenants"
            console.log(`${apiUrl}/${private_any_api_base_path}/${resource}`)
            const { data } = await httpClient.post(url, variables);
            return {
                data: data,
            };
        }
        else {
            
            // const url = `${apiUrl}/${resource}`;
            const url = `${apiUrl}/${private_any_api_base_path}/${resource}`;

            const { data } = await httpClient.post(url, variables);

            return {
                data,
            };
        }
    },

    update: async ({ resource, id, variables }) => {
        console.log("update provider")
        console.log(variables)
        console.log("end variables update provider")

        if (resource == "permission-documents" ){
            console.log("Updating the permission document to the latest value")
            const { data } = await httpClient.put(`${apiUrl}/${private_any_api_base_path}/${resource}/${id}`, variables);
            console.log("Finished Updating the permission document to the latest value")
            return {
                data,
            };
            
        }
        else if (resource == "disputes" ){
            console.log("Updating the disputes to the latest value")
            const { data } = await httpClient.put(`${apiUrl}/${private_any_api_base_path}/${resource}/${id}`, variables);
            console.log("Finished Updating the dispute to the latest value")
            return {
                data,
            };
            
        }
        else{

            const url = `${apiUrl}/${resource}/${id}`;

            const { data } = await httpClient.patch(url, variables);

            return {
                data,
            };
        }
    },

    updateMany: async ({ resource, ids, variables }) => {
        console.log("update many provider")
        const response = await Promise.all(
            ids.map(async (id) => {
                const { data } = await httpClient.patch(
                    `${apiUrl}/${resource}/${id}`,
                    variables,
                );
                return data;
            }),
        );

        return { data: response };
    },

    createMany: async ({ resource, variables }) => {
        console.log("create many provider")
        const url = `${apiUrl}/${resource}/bulk`;

        const { data } = await httpClient.post(url, { bulk: variables });

        return {
            data,
        };
    },

    
    getOne: async ({ resource, id, meta }) => {
        console.log("get one provider")
        console.log("from get one ")

        if (resource == "transactions" ){
            console.log("Getting transactions")
            console.log(meta)
            const address = meta?.address 
            const chain = meta?.chain
            const { data } = await httpClient.get(`${apiUrl}/${private_get_api_base_path}/${resource}/${id}?chain=sepolia`);
            console.log("Finished Getting transactions")
            console.log(data)
            return {
                data: data,
                total: 100,
            };
        }
        else if (resource == "permission-documents" ){
            // const url = `${apiUrl}/${resource}/${id}`;
            const url = `${apiUrl}/${public_get_api_base_path}/${resource}/${id}`;
            console.log(`${apiUrl}/${public_get_api_base_path}/${resource}/${id}`)
            const { data } = await httpClient.get(url)
            
            console.log("end from get one ")
            console.log(data)
            return {
                data,
            };
        }
        else if (resource == "permission-documents-show" ){
            // const url = `${apiUrl}/${resource}/${id}`;
            const url = `${apiUrl}/${public_get_api_base_path}/permission-documents/${id}`; // A hack to display the json correctly
            console.log(`${apiUrl}/${public_get_api_base_path}/permission-documents/${id}`)
            const { data } = await httpClient.get(url)
            
            console.log("end from get one ")
            return {
                data,
            };
        }
        else if (resource == "disputes" ){
            const url = `${apiUrl}/${private_get_api_base_path}/disputes/${id}`; 
            console.log(`${apiUrl}/${private_get_api_base_path}/disputes/${id}`)
            const { data } = await httpClient.get(url)
            
            console.log(data)
            console.log("end from get one ")
            return {
                data,
            };
        }
        else{
            // const url = `${apiUrl}/${resource}/${id}`;
            const url = `${apiUrl}/${private_get_api_base_path}/${resource}/${id}`;

            const { data } = await httpClient.get(url)
            
            console.log("end from get one ")
            return {
                data,
            };
        }
    },

    deleteOne: async ({ resource, id }) => {
        console.log("delet eone provider")
        const url = `${apiUrl}/${resource}/${id}`;

        const { data } = await httpClient.delete(url);

        return {
            data,
        };
    },

    deleteMany: async ({ resource, ids }) => {
        console.log("delete many provider")
        const response = await Promise.all(
            ids.map(async (id) => {
                const { data } = await httpClient.delete(
                    `${apiUrl}/${resource}/${id}`,
                );
                return data;
            }),
        );
        return { data: response };
    },

    getApiUrl: () => {
        console.log("get api provider")
        return apiUrl;
    },

    custom: async ({
        url,
        method,
        meta,
        filters,
        sorters,
        payload,
        query,
        headers,
    }) => {
        console.log("custom provider")
        let requestQueryBuilder = RequestQueryBuilder.create();

        requestQueryBuilder = handleFilter(requestQueryBuilder, filters);

        requestQueryBuilder = handleJoin(requestQueryBuilder, meta?.join);

        requestQueryBuilder = handleSort(requestQueryBuilder, sorters);

        let requestUrl = `${url}?${requestQueryBuilder.query()}`;

        if (headers) {
            httpClient.defaults.headers = {
                ...httpClient.defaults.headers,
                ...headers,
            };
        }

        let axiosResponse;
        switch (method) {
            case "put":
            case "post":
            case "patch":
                axiosResponse = await httpClient[method](url, payload);
                break;
            case "delete":
                axiosResponse = await httpClient.delete(url, {
                    data: payload,
                });
                break;
            default:
                axiosResponse = await httpClient.get(requestUrl);
                break;
        }

        const { data } = axiosResponse;

        return Promise.resolve({ data });
    },
});
