import React from "react";
import { createRoot } from "react-dom/client";
import { Auth0Provider } from "@auth0/auth0-react";

import App from "./App";

const container = document.getElementById("root");
// eslint-disable-next-line
const root = createRoot(container!);

const domain = process.env.REACT_APP_AUTH0_DOMAIN || ""
const client_id = process.env.REACT_APP_AUTH0_CLIENT_ID || ""
const audience = process.env.REACT_APP_API_URL || ""

root.render(
    <React.StrictMode>
            <Auth0Provider
        domain={domain}
        clientId={client_id}
        useRefreshTokens={true}
        cacheLocation="localstorage"
        authorizationParams={{
            redirect_uri: window.location.origin,
            audience: audience,
            tenant_id: localStorage.getItem('tenant_id') ? localStorage.getItem('tenant_id') : "root_tenant",
            setup_recently_migrated_to_new_continent_config: localStorage.getItem('setup_recently_migrated_to_new_continent_config') ? localStorage.getItem('setup_recently_migrated_to_new_continent_config') : ""
        }}        
      >
        <App />
        </Auth0Provider>
    </React.StrictMode>
    ,
);
