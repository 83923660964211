import React, { useEffect, useState } from 'react';

import { useNavigate } from 'react-router-dom';

import axios from 'axios';
import { Link } from 'react-router-dom';

import { useAuth0, withAuthenticationRequired } from "@auth0/auth0-react";
import { 
    Row,
    Col,
    Tag,
    Layout as AntdLayout,
    Card,
    Typography,
    Form,
    Input,
    Button,
    Checkbox,
    Tabs, 
    TabPane,  
    Space,
    Tooltip,
    Avatar,
    Modal,
    Table,
    Select,
    Spin,
  } from 'antd';
import RoleAndPermissions from './components/profile'
import { Show, MarkdownField,  List, TextField,
  FilterDropdown, EditButton, ShowButton, Edit, 
  useTable, useModalForm} from "@refinedev/antd";

const { Text, Title } = Typography;

import {
  FieldTimeOutlined,
  AuditOutlined,
  BarChartOutlined, 
  DashboardOutlined,
  AlignLeftOutlined,
  ExperimentOutlined,
  SettingOutlined
} from "@ant-design/icons";

import { useNotification } from "@refinedev/core";
// import { IUser } from "interfaces";


export default function Settings() {
  const API_URL = process.env.REACT_APP_API_URL || ""
  const { open } = useNotification();

  const { user, getAccessTokenSilently, getIdTokenClaims } = useAuth0();
  const TabPane = Tabs.TabPane

  let available_regions = new Map([
    ["US", "US - US West (Oregon) and US East (N. Virginia)"],
    ["EU", "EU - Europe (Frankfurt) and Europe (Ireland)"],
    ["AP", "AP - Asia Pacific (Tokyo) and Asia Pacific (Singapore)"],
    ["IN", "IN - Asia Pacific (Mumbai) and Asia Pacific (Hyderabad)"]
  ]);

  let scopes = []

  // Sort the scopes alphabetically
  Object.values(user[process.env.REACT_APP_BASE_URL + "/role_permissions"]).forEach(role => {
    let arr = []
    role.scopes.forEach(scope => arr.push(scope))
    scopes = scopes.concat(arr)
    scopes = Array.from(new Set(scopes));
  });
  // Sort the scopes alphabetically
  let arr = []
  scopes.forEach(scope => arr.push(scope))
    scopes = scopes.concat(arr)
    scopes = Array.from(new Set(scopes));

  const [editFamilyName, setEditFamilyName] = React.useState('')
  const [editGivenName, setEditGivenName] = React.useState('')
  const [editOrganizationnName, setEditOrganizationName] = React.useState('')

  function toggleEdit(e) {
    if(e.target.id == "family_name"){
      setEditFamilyName(true)
    }
    if(e.target.id == "given_name"){
      setEditGivenName(true)
    }
    if(e.target.id == "organization_name"){
      setEditOrganizationName(true)
      setEnableOrganizationSaveButton(true)
    }
    console.log(e.target.id)
  }

  const [enableOrganizationSaveButton, setEnableOrganizationSaveButton] = React.useState(false)
  const [organizationName, setOrganizationName] = React.useState(user[process.env.REACT_APP_BASE_URL + '/org']?.name)

  function onOrganizationNamechange(e){
    setOrganizationName(e.target.value)
  }

  async function saveOrganizationDetailsButton(e) {
    console.log(e.target.id)
    console.log(organizationName)
    setEnableOrganizationSaveButton(false)
    if(organizationName != user[process.env.REACT_APP_BASE_URL + '/org']?.name){
      console.log("organization save button clicked")
      
      const url = "https://pdx.us.management.sandbox.authe.io/ANY/US/v0/organizations/" + user[process.env.REACT_APP_BASE_URL + '/org_id']

      const { data, status } = await axios.post(
        url,
        {
          "name": organizationName
        }
      ).catch(function (error) {
        console.log(error)

        open?.({
          type: "error",
          message: "Error",
          description: "Unknown error",
          key: "organization_name",
        });

        setEnableOrganizationSaveButton(true)
      })

      console.log("Organization name updated: " + JSON.stringify(data))

    
      if(data.error){
        open?.({
          type: "error",
          message: "Error",
          description: "Unknown error while updating the organization name",
          key: "organization_name",
        });
        setEnableOrganizationSaveButton(true)
      }
      else{
        // Get the new token with the updated org name
        const tenant_id = localStorage.getItem('tenant_id') ? localStorage.getItem('tenant_id') : "root_tenant"
        const access_token = await getAccessTokenSilently( { cacheMode: 'off', authorizationParams: {"tenant_id": tenant_id }});
        
        await getIdTokenClaims({ cacheMode: 'off'});

        open?.({
          type: "success",
          message: "Successfully updated the organization name",
          description: "Successfully updated the organization name",
          key: "organization_name",
        });
        setEditOrganizationName(false)
      }
    }
    else{
        // Get the new token with the updated org name
        const tenant_id = localStorage.getItem('tenant_id') ? localStorage.getItem('tenant_id') : "root_tenant"
        const access_token = await getAccessTokenSilently( { cacheMode: 'off', authorizationParams: {"tenant_id": tenant_id }});
        
        await getIdTokenClaims({ cacheMode: 'off'});

        open?.({
          type: "success",
          message: "Successfully updated the organization name",
          description: "Successfully updated the organization name",
          key: "organization_name",
        });
        setEditOrganizationName(false)
    }
    

  }

  let [changedRoles, setChangedRoles] = React.useState(user[process.env.REACT_APP_BASE_URL + '/roles'])
  
  const handleReloadRoles = async (value) => {
    // console.log(value);

    const url = "https://us.api.sandbox.authe.io/management/permissions/GET/US/v0/roles"

    const { data, status } = await axios.get(
      url,
    ).catch(function (error) {
      console.log(error)

      open?.({
        type: "error",
        message: "Error",
        description: "Unknown error",
        key: "roles",
      });
    })

    console.log("Roles retrieved: " + JSON.stringify(data))

  
    if(data.error){
      open?.({
        type: "error",
        message: "Error",
        description: "Unknown error while getting the roles",
        key: "roles",
      });
    }
    else{
      // Reset array with available roles
      setAvailableRoles([])
      data.result.forEach( role => {
        const obj = {"label": role.role_name, "value": role.id}
        setAvailableRoles(prevState => [...prevState, obj])
      })

      open?.({
        type: "success",
        message: "Successfully retrieved the roles",
        description: "Successfully retrieved the roles",
        key: "roles",
      });
    }
  };

  const handleSelectedRoleChange = (value) => {
    changedRoles = value
    console.log(availableRoles)
    setChangedRoles(value)
  };

  let [availableRoles, setAvailableRoles] = React.useState(JSON.parse(process.env.REACT_APP_DEFAULT_APP_ROLES)["roles"])

  let [isLoadingSubmitRoleChange, setIsLoadingSubmitRoleChange] = React.useState(false)
  const handleSubmitdRoleChange = async (value) => {
    setIsLoadingSubmitRoleChange(true)
    console.log(changedRoles);

    const existing_roles = user[process.env.REACT_APP_BASE_URL + '/roles']
    const roles_to_remove = []
    const roles_to_add = []
    
    changedRoles.forEach(role => {
      // convert role id to role name
      var role_name = ""
      availableRoles.forEach(available_role => {
        if(available_role.value == role){
          role_name = available_role.label
        }
      })

      if (existing_roles.indexOf(role_name) > -1){
        console.log("Role is already there: " + role_name)
      }
      else{
        console.log("Role needs to be added: " + role_name)
        roles_to_add.push(role)
      }
    })

    existing_roles.forEach(role_name => {
      // convert role name to role id
      // If roles are not loaded then custom role id is not available. So this needs to be computed on the backend
      var role_id = ""
      availableRoles.forEach(available_role => {
        if(available_role.label == role_name){
          role_id = available_role.value
        }
      })

      if (changedRoles.indexOf(role_id) > -1){
        console.log("Role is already there: " + role_name)
      }
      else if(role_id == ""){
        console.log("Role needs to be removed: " + role_name)
        roles_to_remove.push(role_name)
      }
      else{
        console.log("Role needs to be removed: " + role_name)
        roles_to_remove.push(role_id)
      }
    })

    console.log("roles to add: " + roles_to_add)
    console.log("roles to remove: " + roles_to_remove)

    const url = "https://pdx.us.management.sandbox.authe.io/management/permissions/ANY/US/v0/users/" + user.sub + "/roles"

    let error = []
    if(roles_to_add.length > 0){
        const { data, status } = await axios.post(
          url,
          {
            "roles": roles_to_add
          }
      ).catch(function (error) {
        console.log(error)

        open?.({
          type: "error",
          message: "Error",
          description: "Unknown error",
          key: "roles",
        });
      })
    
      console.log("Roles added updated: " + JSON.stringify(data))
      if(data?.error){
        error.push(data?.error)
      }
    }
    
    if(roles_to_remove.length > 0){
      const { data_deleted, status_deleted } = await axios.delete(
        url,
        { data:
          {
            "roles": roles_to_remove
          }
        }
      ).catch(function (error) {
        console.log(error)

        open?.({
          type: "error",
          message: "Error",
          description: "Unknown error",
          key: "roles",
        });
      })

      console.log("Roles removed updated: " + JSON.stringify(data_deleted))
      if(data_deleted?.error){
        error.push(data_deleted?.error)
      }
    }

    if(error.length > 0){
      open?.({
        type: "error",
        message: "Error",
        description: "Unknown error while updating the roles",
        key: "roles",
      });
    }
    else{
      open?.({
        type: "success",
        message: "Roles Updated",
        description: "Roles updated",
        key: "roles",
      });
    }
    
    const tenant_id = localStorage.getItem('tenant_id') ? localStorage.getItem('tenant_id') : "root_tenant"
    const access_token = await getAccessTokenSilently( { cacheMode: 'off', authorizationParams: {"tenant_id": tenant_id }});
    
    await getIdTokenClaims({ cacheMode: 'off'});

    setIsLoadingSubmitRoleChange(false)
  };

  const handleChangeTenant = async (value) => {
    console.log(value.target.id)
    localStorage.setItem('tenant_id', value.target.id);

    await getAccessTokenSilently( { cacheMode: 'off', authorizationParams: {"tenant_id": value.target.id }});

    await getIdTokenClaims({ cacheMode: 'off', authorizationParams: {"tenant_id": value.target.id }}); 
    
    open?.({
      type: "success",
      message: "Successfully switched tenant",
      description: "Successfully switched tenant",
      key: "tenants",
    });

  };

  let [isLoadingGetTenants, setIsLoadingGetTenants] = React.useState(false)
  let [tenants, setTenants] = React.useState([user[process.env.REACT_APP_BASE_URL + "/tenant"]])  

  const getTenants = async (value) => {
    setIsLoadingGetTenants(true)

    console.log("Get tenants")

    const url = "https://us.api.sandbox.authe.io/management/GET/US/v0/organizations/" + user[process.env.REACT_APP_BASE_URL + '/org_id'] +  "/tenants" 
    const { data, status } = await axios.get(
      url,
    ).catch(function (error) {
      console.log(error)

      open?.({
        type: "error",
        message: "Error",
        description: "Unknown error",
        key: "tenants",
      });
    })

    console.log("Tenants retrieved: " + JSON.stringify(data))

  
    if(data?.error){
      open?.({
        type: "error",
        message: "Error",
        description: "Unknown error while getting the tenants",
        key: "users",
      });
      setIsLoadingGetTenants(false)
    }
    else{
      // // Reset array with available roles
      setTenants([])
      data.result.forEach( tenant => {
        const obj = {
          "id": tenant.tenant_id, 
          "name": tenant.tenant_name, 
          "continent": tenant.tenant_continent, 
          "is_root_tenant":  tenant.is_root_tenant ? true : false
        }
        setTenants(prevState => [...prevState, obj])
      })

      open?.({
        type: "success",
        message: "Successfully retrieved the tenants",
        description: "Successfully retrieved the tenants",
        key: "users",
      });
    }
    setIsLoadingGetTenants(false)
  };
  
  // Create Tenant Modal
  const {
      modalProps: createTenantModalProps,
      formProps: createTenantFormProps,
      show: createTenantModalShow,
      formLoading: createTenantFormLoading,
  } = useModalForm({
      action: "create",
      syncWithLocation: true,
      resource:"tenants"
  });
      
  let [isLoadingCreateTenant, setIsLoadingCreateTenants] = React.useState(false)
  const createTenant = async (value) => {
    console.log("Create tenant")
    createTenantModalShow()
  };


  let [organizationMembers, setOrganizationMembers] = React.useState([])

  let [isLoadingGetOrganizationMembers, setIsLoadingGetOrganizationMembers] = React.useState(false)
  const getOrganizationMembers = async () => {
    setIsLoadingGetOrganizationMembers(true)
    console.log("Get members");

    const url = "https://us.api.sandbox.authe.io/management/permissions/GET/US/v0/users"

    const { data, status } = await axios.get(
      url,
    ).catch(function (error) {
      console.log(error)

      open?.({
        type: "error",
        message: "Error",
        description: "Unknown error",
        key: "users",
      });
    })

    console.log("Organization members retrieved: " + JSON.stringify(data))

  
    if(data.error){
      open?.({
        type: "error",
        message: "Error",
        description: "Unknown error while getting the organization members",
        key: "users",
      });
    }
    else{
      // Reset array with available roles
      setOrganizationMembers([])
      data.result.forEach( user => {
        const obj = {
          "id": user.id, 
          "name": user.name, 
          "email": user.email, 
          "roles": user.roles ? user.roles.join(', ') : "None" , 
          "status": user.status, 
        }
        setOrganizationMembers(prevState => [...prevState, obj])
      })

      open?.({
        type: "success",
        message: "Successfully retrieved the organization members",
        description: "Successfully retrieved the organization members",
        key: "users",
      });
    }

    setIsLoadingGetOrganizationMembers(false)
  }
  
    // Create Invite org member Modal
    const {
      modalProps: createInviteOrgMemberModalProps,
      formProps: createInviteOrgMemberFormProps,
      show: createInviteOrgMemberModalShow,
      formLoading: createInviteOrgMemberFormLoading,
  } = useModalForm({
      action: "create",
      syncWithLocation: true,
      resource:"users/invite"
  });

  const inviteOrgMember = async (value) => {
    console.log("Invite org member")
    createInviteOrgMemberModalShow()
  };

  
  return (
    <Show
    title="Settings"
    resource="posts"
    recordItemId={1}
    isLoading={false}
    headerProps={{
        backIcon: false,
    }}
    headerButtons={
        <Space
            key="action-buttons"
            style={{ float: "right", marginRight: 24 }}
        >
            <Button
                danger
                disabled={false}
                onClick={() =>
                    record && handleUpdate(record, "rejected")
                }
            >
                Reset All Tabs
            </Button>
            <Button
                type="primary"
                disabled={false}
                onClick={() =>
                    record && handleUpdate(record, "published")
                }
            >
                Save All Tabs
            </Button>
        </Space>
    }
>
  
    <Space>
      <Tabs defaultActiveKey="1" 
          tabPosition="top"
          style={{fontSize:18}}
        >



        <TabPane label="general" tab={<span style={{fontSize:18}}>General</span>} key="1">
        <Title level={5}>Selected Region</Title>
            {/* <Text> {user["https://authe.io/continent"]}</Text> */}
            <Text> 
              <Space wrap>
              <Tooltip title="Not allowed to change this after signup">
              <TextField disabled={true}
                                strong
                                value={ available_regions.get(user[process.env.REACT_APP_BASE_URL + "/continent"]) +  '(currently selected)'
                                }
                            />
              </Tooltip>
            </Space>          
            </Text> 

            <Title level={5}>Available Regions</Title>
            {/* <Text> {user["https://authe.io/continent"]}</Text> */}
            <Text> 
              <Space wrap>
              <Tooltip title="In which region your data is stored">
              <Select
                defaultValue={user[process.env.REACT_APP_BASE_URL + "/continent"]}
                style={{ width: 500 }}
                options={[
                  { value: "US", label: 'US - US West (Oregon) and US East (N. Virginia)'},
                  { value: "EU", label: 'EU - Europe (Frankfurt) and Europe (Ireland)' },
                  { value: "AP", label: 'AP - Asia Pacific (Tokyo) and Asia Pacific (Singapore)'  },
                  { value: "IN", label: 'IN - Asia Pacific (Mumbai) and Asia Pacific (Hyderabad)'  },
                ]}
              />
              </Tooltip>
            </Space>          
            </Text> 
            
            <Title level={5}>Language</Title>
            <Text> 
              <Space wrap>
              <Select
                defaultValue="english"
                style={{ width: 500 }}
                options={[
                  { value: "english", label: 'English' },
                ]}
              />
            </Space>          
            </Text> 

        </TabPane>




        <TabPane label="profile" tab={<span style={{fontSize:18}}>Profile</span>} key="2">
            <Title level={5}>Profile Picture</Title>
            <Card
                hoverable
                style={{ width: 240 }}
                cover={
                    <img
                      alt="profile_picture"
                      style={{ height: "100%" }}
                      src={user?.picture}
                    />
                }
              >
            </Card>
            
            <Form
    name="complex-form"
    labelCol={{ span: 4 }}
    wrapperCol={{ span: 16 }}
    style={{ maxWidth: 600}}
  >


    <Form.Item label="Username">
      <Space>
        <Form.Item
          name="username"
          noStyle
          rules={[{ required: true, message: 'Username is required' }]}
          initialValue={user.nickname}
        >
          <Input style={{ width: 160 }} placeholder="Please input" defaultValue={user.nickname} disabled={true}/>
        </Form.Item>
      </Space>
    </Form.Item>
    <Form.Item label="Name">
      <Space>
        <Form.Item
          name="given_name"
          noStyle
          rules={[{ required: true, message: 'Name is required' }]}
          initialValue={user.given_name}
        >
          <Input style={{ width: 160 }} placeholder="Please input" defaultValue={user.given_name} disabled={!editGivenName}/>
        </Form.Item>
        <Typography.Link id="given_name" onClick={(e) => {{toggleEdit(e)}}} href="#">Edit</Typography.Link>
      </Space>
    </Form.Item>

    <Form.Item label="Lastname">
      <Space>
        <Form.Item
          name="last_name"
          noStyle
          rules={[{ required: true, message: 'Lastname is required' }]}
          initialValue={user.family_name}
        >
          <Input style={{ width: 160 }} placeholder="Please input" defaultValue={user.family_name} disabled={!editFamilyName}/>
        </Form.Item>
        <Typography.Link id="family_name" onClick={(e) => {{toggleEdit(e)}}} href="#">Edit</Typography.Link>
      </Space>
    </Form.Item>
    <Form.Item label="Email" style={{ marginBottom: 0 }}>
      <Form.Item
        name="email"
        rules={[{ required: true }]}
        style={{ display: 'inline-block', width: 'calc(50% - 8px)' }}
        initialValue={user.email}
        >
        <Input placeholder="Input email" defaultValue={user.email} disabled={true}/>
      </Form.Item>
    </Form.Item>
    <Form.Item label=" " colon={false}>
      <Button type="primary" htmlType="submit">
        Submit
      </Button>
    </Form.Item>
  </Form>
           
  </TabPane>

        <TabPane label="permissions" tab={<span style={{fontSize:18}}>Roles and Permissions</span>} key="3">
            <Title level={5}>Current Role(s)</Title>

            <Tooltip title="Which role(s) you have in the application">
            <Text> 
              <Text   >
                    {
                          user[process.env.REACT_APP_BASE_URL + '/roles'].map(row=>{
                            return <Tag color={"geekblue"} key={row}>{row}</Tag>
                        })
                    }
                </Text>
            </Text>
            </Tooltip>

            <Title level={5}>Available Roles</Title>
            <Text > 
              <Space wrap>
              <Tooltip title="Which role(s) you have in the application">
              <Select
                defaultValue={user[process.env.REACT_APP_BASE_URL + "/roles"]}
                style={{ width: 500 }}
                mode="multiple"
                allowClear
                placeholder="Please select"
                onChange={handleSelectedRoleChange}
                options={availableRoles}
              />
              </Tooltip>
              <Typography.Link id="reload_roles" onClick={(e) => {{handleReloadRoles(e)}}} href="#" style={{margin:"10px"}}>Reload roles</Typography.Link>
              <Button id="save_role" type="primary" disabled={isLoadingSubmitRoleChange} onClick={handleSubmitdRoleChange} >Save</Button>
            </Space>          
            </Text>


            <Title level={5}>Combined Permissions currently attached</Title>
            <Text> 
              {/* <Tag color={"geekblue"}>{user['https://authe.io/roles']}</Tag> */}
              <Tag  color={"geekblue"} >
                    {
                        scopes.map(scope=>{
                          return <Form>
                          <Form.Item label="" name={scope} >
                              <Checkbox defaultChecked={true}>{scope}</Checkbox>
                          </Form.Item>
                        </Form>
                        })
                    }
                </Tag>
            </Text>


            <Title level={5}>Create a custom application role</Title>
            <Link to="/settings/roles/create"> <Button id="save_new_role" type="primary"  style={{margin:"10px"}} >Create a new Role</Button></Link>
        </TabPane>

        <TabPane label="organization" tab={<span style={{fontSize:18}}>Organization</span>} key="4">
        <Form
          // layout="vertical"
          name="organization-form"
          labelCol={{ span: 5 }}
          wrapperCol={{ span: 16 }}
          style={{ maxWidth: 700, width: 700 }}
        >
          <Title level={5}>Organization details:</Title>
        <Form.Item label="Organization Name:">
          <Space>
            <Form.Item
              label="Organization Name"
              name="organization_name"
              noStyle
              rules={[{ required: true, message: 'Organization name is required' }]}
              initialValue={user[process.env.REACT_APP_BASE_URL + '/org']?.name}
            >
              <Input id="organization_name" style={{ width: 200 }} value={user[process.env.REACT_APP_BASE_URL + '/org']?.name} onChange={(e) => {{onOrganizationNamechange(e)}}} placeholder="Please input" defaultValue={user[process.env.REACT_APP_BASE_URL + '/org']?.name} disabled={!editOrganizationnName}/>
            </Form.Item>
            <Typography.Link id="organization_name" onClick={(e) => {{toggleEdit(e)}}} href="#">Edit</Typography.Link>
          </Space>
        </Form.Item>
        <Form.Item label=''>
              <Button id="organization" onClick={(e) => {{saveOrganizationDetailsButton(e)}}} type="primary" disabled={!enableOrganizationSaveButton}>Update</Button>
        </Form.Item>

        <Title level={5}>Tenants:</Title>
            <Text> 
                    {
                        tenants.map(tenant=>{
                          return <Form>
                            <Form.Item label="Tenant Name:">
                              <Space>
                                <Form.Item
                                  label="Tenant Name"
                                  name={tenant.name}
                                  noStyle
                                  rules={[{ required: true, message: 'Organization name is required' }]}
                                  initialValue={tenant.name}
                                >
                                  <Input id={tenant.name} style={{ width: 200 }} placeholder="" defaultValue={tenant.name} disabled={true}/>
                                  <Tooltip title="In which region your data is stored"> <Input id={tenant.continent} style={{ width: 45 }} placeholder="" defaultValue={tenant.continent} disabled={true}/></Tooltip>
                                </Form.Item>
                                <Typography.Link id={tenant.id} onClick={(e) => {{handleChangeTenant(e)}}} href="#">Switch</Typography.Link>
                              </Space>
                            </Form.Item> 
                        </Form>
                        })
                    }
            </Text>
          <Button id="get_tenants" type="primary" onClick={getTenants} disabled={isLoadingGetTenants} >Get Tenants</Button>
          <Button id="create_tenant" type="default" onClick={createTenant} disabled={isLoadingCreateTenant} style={{margin:"10px"}} >Create Tenant</Button>
          <Modal {...createTenantModalProps} >
                <Spin spinning={createTenantFormLoading}>
                    <Form {...createTenantFormProps} layout="vertical" style={{ width: 500 }}>
                        <Form.Item
                            label="Tenant name"
                            name="name"
                            style={{ width: 500 }}
                            rules={[
                                {
                                    required: true,
                                },
                            ]}
                        >
                            <Input />
                        </Form.Item>
                        <Tooltip title="In which region your data is stored">  
                          <Form.Item
                              label="Continent"
                              name="continent"
                              initialValue={user[process.env.REACT_APP_BASE_URL + "/continent"]}
                              rules={[
                                  {
                                      required: true,
                                  },
                              ]}
                          >
                        <Select
                          defaultValue={user[process.env.REACT_APP_BASE_URL + "/continent"]}
                          style={{ width: 500 }}
                          options={[
                            { value: "US", label: 'US - US West (Oregon) and US East (N. Virginia)'},
                            { value: "EU", label: 'EU - Europe (Frankfurt) and Europe (Ireland)' },
                            { value: "AP", label: 'AP - Asia Pacific (Tokyo) and Asia Pacific (Singapore)'  },
                            { value: "IN", label: 'IN - Asia Pacific (Mumbai) and Asia Pacific (Hyderabad)'  },
                          ]}
                        />
                          </Form.Item>
                        </Tooltip>
                    </Form>
                </Spin>
            </Modal>
<br></br>
<br></br>
<br></br>

        <Title level={5}>Organization members:</Title>
      </Form>
              <Table  rowKey="id" dataSource={organizationMembers}>
                  <Table.Column dataIndex="id" title="Id" />
                  <Table.Column dataIndex="name" title="Name" />
                  <Table.Column dataIndex="email" title="Email" />
                  <Table.Column dataIndex="roles" title="Role(s)" />
                  <Table.Column dataIndex="status" title="Status" />
                  <Table.Column
                    title="Actions"
                    dataIndex="id"
                    render={(_, id) => (
                        <Space>
                            <EditButton
                                hideText
                                size="small"
                                recordItemId={id.id}
                                resource="permission-documents"
                            />
                            <ShowButton
                                hideText
                                size="small"
                                recordItemId= {id.id}
                                resource="permission-documents"
                            />
                        </Space>
                    )}
                />
              </Table>
              <Button id="organization_members" type="primary" onClick={getOrganizationMembers} disabled={isLoadingGetOrganizationMembers} >Get members</Button>
              <Button id="invite_organization_members" type="default" onClick={inviteOrgMember} style={{margin:"10px"}}>Invite New members</Button>
              <Modal {...createInviteOrgMemberModalProps} >
                <Spin spinning={createInviteOrgMemberFormLoading}>
                    <Form {...createInviteOrgMemberFormProps} layout="vertical" style={{ width: 500 }}>
                        <Form.Item
                            label="User email"
                            name="email"
                            style={{ width: 500 }}
                            rules={[
                                {
                                    required: true,
                                    type: "email",
                                    message: "The input is not a valid E-mail address",
                                },
                            ]}
                        >
                            <Input />
                        </Form.Item>
                        <Tooltip title="Which role(s) the new user will have in the application">
                          <Form.Item
                              label="Roles"
                              name="roles"
                              value={[ "sandbox-Admin"]}
                              initialValue={[]}
                              rules={[
                                  {
                                      required: false,
                                  },
                              ]}
                          >
                        <Select
                            style={{ width: 500 }}
                            mode="multiple"
                            allowClear
                            placeholder="Please select"
                            // onChange={handleSelectedRoleChange}
                            options={availableRoles}
                        />
                          </Form.Item>
                        </Tooltip>
                        <Typography.Link id="reload_roles" onClick={(e) => {{handleReloadRoles(e)}}} href="#" style={{margin:"10px"}}>Reload roles</Typography.Link>
                        <Form.Item 
                            hidden={true}
                            label="Tenant Id"
                            name="tenant_id"
                            style={{ width: 500 }}
                            initialValue={user[process.env.REACT_APP_BASE_URL + "/tenant_id"]}
                            rules={[
                                {
                                    required: true,
                                },
                            ]}
                        >
                            <Input defaultValue={user[process.env.REACT_APP_BASE_URL + "/tenant_id"]}/>
                        </Form.Item>
                    </Form>
                </Spin>
            </Modal>
        </TabPane>
        
        <TabPane label="addresses" tab={<span style={{fontSize:18}}>Addresses</span>} key="5">
            <Table  rowKey="id">
                  <Table.Column dataIndex="friendly_name" title="Friendly name" />
                  <Table.Column dataIndex="address" title="Address" />
                  <Table.Column dataIndex="tags" title="Tags" />
                  <Table.Column dataIndex="category" title="Category" />
                  <Table.Column dataIndex="labels" title="Labels" />
                  <Table.Column dataIndex="include_in_search_results" title="Include in Search Results" />
                  {/* <Table.Column
                      dataIndex="title"
                      title="Title"
                      key="title"
                      sorter
                  /> */}
              </Table>
              <Button id="get_addresses" type="primary">Get addresses</Button>
              <Button id="add_address" type="default" style={{margin:"10px"}}>Add adddress</Button>
        </TabPane>

        <TabPane label="advanced" tab={<span style={{fontSize:18}}>Advanced</span>} key="6">
            <Title level={5}>API Keys</Title>
            <Form >
              <Row gutter={24}>
                <Col span={8}>
                  <Form.Item label='API_KEY_ID'>
                    <Input disabled={true} />
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={24}>
              <Col span={8}>
                  <Form.Item label=''>
                    <Button type="primary">Generate a new key</Button>
                  </Form.Item>
                </Col>
              </Row>
            </Form>
            <Title level={5}>Bring your own database</Title>
            <Form >
              <Row gutter={2}>
                <Col span={8}>
                  <Form.Item label='Database name and endpoint'>
                    <Input disabled={true} value="https://your_endpoint_here" style={{width:"200px"}}/>
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={24}>
              <Col span={8}>
                  <Form.Item label=''>
                    <Button type="primary">Save</Button>
                  </Form.Item>
                </Col>
              </Row>
            </Form>
        </TabPane>
    </Tabs>
  </Space>
</Show>
  );
}


